import { useState, useRef } from 'react'
import ReactDOM from 'react-dom'

import './styles.css'
import App from './App'

function Footer({ link1, link2 }) {
  return (
    <div className="pmndrs-menu">
      <div>{/* <Logo style={{ width: 42, height: 42 }} color="#b0b0b0" /> */}</div>
      <div>
        <span>
          <b>Squidwards House</b>
        </span>
        <a href="https://t.me/+NDn_jkUWvidmOGNh">Telegram</a>
        <a href="https://twitter.com/SquidHouselol">Twitter</a>
        <a href="https://www.pump.fun/AHeBcb5kGfkKZuw47qXbah5YRkCAHZdv2FhbfCYwh6tx">Pump</a>
      </div>
      <a
        onClick={() => copy('AHeBcb5kGfkKZuw47qXbah5YRkCAHZdv2FhbfCYwh6tx')}
        className="break-words text-sm font-medium text-green-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600 sm:text-base"
      >
        7Q8Q6QbxsgiwAgfGMWWm 2wU4bi1sWELCC4Vt3ypw3BM2
      </a>
      <div>
        <span>Open Source</span>
        <span>{`I <3 You`}</span>
      </div>
      <div style={{ width: '100%' }} />
      <div>
        <b>{link1}</b>
        {link2}
      </div>
    </div>
  )
}

function copy(text) {
  navigator.clipboard.writeText(text)
  alert('Copied to Clipboard!')
}

function SpeedSlider({ speed, setSpeed }) {
  return (
    <div style={{ position: 'absolute', top: 20, left: 20, zIndex: 100 }}>
      <input
        type="range"
        min="1"
        max="100"
        value={speed}
        onChange={(e) => setSpeed(Number(e.target.value))}
        style={{ width: 200 }}
      />
      <span>Speed: {speed}</span>
    </div>
  );
}

function Overlay() {
  const [ready, set] = useState(false)
  const [speed, setSpeed] = useState(1); // Starting with a default speed of 1
  return (
    <>
      {ready && <App props={speed} />}
      <div className={`fullscreen bg ${ready ? 'ready' : 'notready'} ${ready && 'clicked'}`}>
        <div className="stack">
          {' '}
          <p> Welcome to Squidwards House!</p>
          <button onClick={() => set(true)}>▶️</button>
        </div>
      </div>
      <SpeedSlider speed={speed} setSpeed={setSpeed} />
      <Footer date="Squidwards House" year="2024" />
    </>
  )
}

ReactDOM.render(<Overlay />, document.getElementById('root'))

import * as THREE from 'three'
import { Suspense, useEffect, useRef, useMemo, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { suspend } from 'suspend-react'
// import Environment from './Environment'
import { OrbitControls, Text } from '@react-three/drei'
// import * as THREE from 'three';
// import { memo } from 'react'
// import { AccumulativeShadows, RandomizedLight, Environment as EnvironmentImpl } from '@react-three/drei'



function createColorGenerator() {
  const colors = [
    "#FF5733", "#33FF57", "#3357FF", "#F833FF", "#33FFF8",
    "#F8FF33", "#FF3380", "#3380FF", "#80FF33", "#FF9633",
    "#9633FF", "#33FF96", "#FF3396", "#3396FF", "#96FF33",
    "#33FFCA", "#CAFF33", "#FFCA33", "#33CAFF", "#CA33FF"
  ];
  let currentIndex = 0;

  return function getNextColor() {
    const color = colors[currentIndex];
    currentIndex = (currentIndex + 1) % colors.length;
    return color;
  };
}

// function BackgroundAnimator({ speed }) {
//   const { gl } = useThree(); // This hook provides access to the Three.js renderer
//   const getNextColor = useMemo(createColorGenerator, []); // Ensures the generator is created only once
//   useFrame(() => {
//     gl.setClearColor(getNextColor()); // Change the background color every frame
//   }, [csg]);

//   return null; // This component does not render anything itself
// }
function BackgroundAnimator({ speed }) {
  const { gl } = useThree();
  const getNextColor = useMemo(createColorGenerator, []);
  const [color, setColor] = useState(getNextColor());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor(getNextColor()); // Update state to trigger re-render with new color
    }, speed);

    return () => clearInterval(intervalId); // Cleanup on unmount or speed change
  }, [speed]); // Dependency array includes speed, so the effect reruns when speed changes

  useEffect(() => {
    gl.setClearColor(color); // Apply the color change when color state updates
  }, [color, gl]);

  return null; // This component does not render anything itself
}

// function BackgroundAnimator({ speed }) {
//   const { gl } = useThree();
//   const timeoutRef = useRef(null);
//   const getNextColor = useMemo(createColorGenerator(), []);
//   let nextColor = getNextColor

//   // // Cleanup timeout when component unmounts or speed changes
//   // useEffect(() => {
//   //   const changeColor = () => {
//   //     gl.setClearColor(nextColor); // Change the background color
//   //     // Set next timeout
//   //     timeoutRef.current = setTimeout(changeColor, speed);
//   //   };

//   //   // Initial call to start the loop
//   //   timeoutRef.current = setTimeout(changeColor, speed);

//   //   return () => clearTimeout(timeoutRef.current);
//   // }, [speed]);

//   return null;
// }

// Extend R3F to include (if not already available)
// extend({ BackgroundAnimator });

function House(props) {
  const csg = useRef()
  return (
    <mesh receiveShadow castShadow {...props}>
      {/* Base of the house - Cylindrical shape */}
      <cylinderGeometry ref={csg} args={[1.5, 1.5, 4, 32]} />
      <meshStandardMaterial color="black" />

      {/* Door - Simplified as a rectangle */}
      <mesh position={[0, -1.5, 1.6]}>
        <boxGeometry args={[0.75, 1, 0.1]} />
        <meshStandardMaterial color="gray" />
      </mesh>

      {/* Windows as Eyes */}
      {/* Left Eye */}
      <mesh position={[-0.5, 1, 1.6]}>
        <circleGeometry args={[0.3, 32]} />
        <meshStandardMaterial color="skyBlue" />
      </mesh>
      {/* Right Eye */}
      <mesh position={[0.5, 1, 1.6]}>
        <circleGeometry args={[0.3, 32]} />
        <meshStandardMaterial color="skyBlue" />
      </mesh>

      {/* Nose */}
      <mesh position={[0, 0.5, 2]}>
        <cylinderGeometry args={[0.15, 0.15, 1, 32]} rotation={[Math.PI / 2, 0, 0]} />
        <meshStandardMaterial color="gray" />
      </mesh>

      {/* Additional details and adjustments can be made here to further match Squidward's house */}
    </mesh>
  )
}



export default function App(props) {
  const csg = useRef()
  const direction = [5, 5, 5]
  return (
    <Canvas shadows dpr={[1, 2]} camera={{ position: [-15, 10, 15], fov: 25 }}>
      <spotLight position={[-4, 4, -4]} angle={0.06} penumbra={1} castShadow shadow-mapSize={[2048, 2048]} />
      <directionalLight position={direction} intensity={0.5} shadow-mapSize={1024} castShadow />
      <directionalLight position={[-5, 5, 5]} intensity={0.1} shadow-mapSize={128} castShadow />
      <directionalLight position={[-5, 5, -5]} intensity={0.1} shadow-mapSize={128} castShadow />
      <directionalLight position={[0, 5, 0]} intensity={0.1} shadow-mapSize={128} castShadow />
      {/* <color attach="background" args={[getNextColor()]} /> */}

      <OrbitControls makeDefault />
      <Suspense fallback={null}>
        <House />
        <BackgroundAnimator attach="background" speed={props.speed} csg={csg}/>
        <Track position-z={-0.25} url="/jelly.mp3" />
        {/* Floating Text above the house */}
        <Text
          color="#000000" // Default black color
          anchorX="center" // Center the text horizontally
          anchorY="middle" // Center the text vertically
          position={[0, 3, 0]} // Adjust the Y position to float above the house
          fontSize={0.5}
        >
          {`Welcome to\nSquidward's House!`}
        </Text>
        <Zoom url="/jelly.mp3" />
        <Text
          color="#fff" // Default black color
          anchorX="center" // Center the text horizontally
          anchorY="middle" // Center the text vertically
          position={[0, 0.2, -0.5]} // Adjust the Y position to float above the house
          fontSize={0.1}
        >
          {`Youre\ninside\nsquidwards\nhouse! `}
        </Text>
      </Suspense>
      <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.025, 0]}>
        <planeGeometry />
        <shadowMaterial transparent opacity={0.15} />
      </mesh>
    </Canvas>
  )
}

function Track({ url, y = 2500, space = 1.8, width = 0.01, height = 0.05, obj = new THREE.Object3D(), ...props }) {
  const ref = useRef()
  // suspend-react is the library that r3f uses internally for useLoader. It caches promises and
  // integrates them with React suspense. You can use it as-is with or without r3f.
  const { gain, context, update, data } = suspend(() => createAudio(url), [url])
  useEffect(() => {
    // Connect the gain node, which plays the audio
    gain.connect(context.destination)
    // Disconnect it on unmount
    return () => gain.disconnect()
  }, [gain, context])

  useFrame((state) => {
    let avg = update()
    // Distribute the instanced planes according to the frequency daza
    for (let i = 0; i < data.length; i++) {
      obj.position.set(i * width * space - (data.length * width * space) / 2, data[i] / y, 0)
      obj.updateMatrix()
      ref.current.setMatrixAt(i, obj.matrix)
    }
    // Set the hue according to the frequency average
    ref.current.material.color.setHSL(avg / 500, 0.75, 0.75)
    ref.current.instanceMatrix.needsUpdate = true
  })
  return (
    <instancedMesh castShadow ref={ref} args={[null, null, data.length]} {...props}>
      <planeGeometry args={[width, height]} />
      <meshBasicMaterial toneMapped={false} />
    </instancedMesh>
  )
}

function Zoom({ url }) {
  // This will *not* re-create a new audio source, suspense is always cached,
  // so this will just access (or create and then cache) the source according to the url
  const { data } = suspend(() => createAudio(url), [url])
  return useFrame((state) => {
    // Set the cameras field of view according to the frequency average
    state.camera.fov = 25 - data.avg / 15
    state.camera.updateProjectionMatrix()
  })
}

async function createAudio(url) {
  // Fetch audio data and create a buffer source
  const res = await fetch(url)
  const buffer = await res.arrayBuffer()
  const context = new (window.AudioContext || window.webkitAudioContext)()
  const source = context.createBufferSource()
  source.buffer = await new Promise((res) => context.decodeAudioData(buffer, res))
  source.loop = true
  // This is why it doesn't run in Safari 🍏🐛. Start has to be called in an onClick event
  // which makes it too awkward for a little demo since you need to load the async data first
  source.start(0)
  // Create gain node and an analyser
  const gain = context.createGain()
  const analyser = context.createAnalyser()
  analyser.fftSize = 64
  source.connect(analyser)
  analyser.connect(gain)
  // The data array receive the audio frequencies
  const data = new Uint8Array(analyser.frequencyBinCount)
  // const getNextColor = createColorGenerator()
  return {

    context,
    source,
    gain,
    data,
    // This function gets called every frame per audio source
    update: () => {
      // getNextColor(),
      analyser.getByteFrequencyData(data)
      // Calculate a frequency average
      return (data.avg = data.reduce((prev, cur) => prev + cur / data.length, 0))
    },
  }
}
